.contact-right{
    width:50%;
    padding: 0px 0rem 0rem 2rem;
}

.contact-photo{
    border-radius:6px;
    height: 15rem;
    background: url("../../images/myphotos/background-2.jpg");
    background-repeat: no-repeat;
    padding: 30px;
    background-size: 100%;
    background-position: center;
}

.container-contact{
    display:flex;
    

}

#message{
    height: 12rem;
    border-radius: 25px;
}

.contact-form{
    width:50%;
    padding: 2rem;
    border-radius: 50px;
    box-shadow: 0 2px 100px 0 #ffc107c4 ;
}

.contact-form div input{
    margin-bottom: 1rem;
    border-radius: 100px;
}

.contact-form div label{
    cursor: pointer;
}

.checkbox-form{
    width:20px;
    height:20px;
}

.contact-logo{
    width: 330px;
    height: 80px;
}


@media (max-width: 976px)
{
    .container-contact{
        flex-direction: column;
    }

    .contact-form, .contact-right{
        width:100%
    }
    .contact-right{
        margin-top:1.5rem;
        padding:0;
    }
}
