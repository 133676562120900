.messages{
  display: flex;
  justify-content: space-between;
  /* margin-top: 25px;
  margin-bottom: 25px; */
  
}

.messages-bottom{
  padding-bottom: 10px;
  display: flex;
  justify-content:  right;
}

.messages-bottom a{
  width: auto;
}


.messages__container-left{
  width:25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--main-color);
  height: 661px;
  
}

.gift-image{
  width: 80px
}



.left-bcolor{
  background-color: #E6E6E6;
  color: black;
}
.right-bcolor{
  background-color:var(--main-lighter-color);
}


.top-info-user{
  display:flex;
  background-color: var(--main-color);
  padding-bottom:15px;
  justify-content: space-between;
  align-items: center;
}

.top-photo-user, .gift-photo{
  margin-top: 10px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}

.container-gifts{
  padding: 0px 10px 10px 10px;
}

.title-top-right{
  font-size: 1rem;
  padding: 1.5rem 1.5rem 0.7rem 1.5rem;
  text-align: center;
}

.title-top-right svg{
  width: 1.2em;
  height: 1.2em;
}
.gift-photo{
  padding:10px;
}
.each-gift{
  /* width:25%; */
  margin: 0px 10px;
  cursor: pointer;
}

.container-gifts{
  flex-wrap: wrap;
}

.message-photo-user{
  width:30px;
}

.top-details-user{
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  margin-left:10px;
}

.top-buttons-user{
  padding-right: 25px;
}

.picker-emoji img{
  width: auto !important;
}

.picker-emoji{
  position: absolute;
  bottom: 80px;
}
.messages-container-left__user-messages{
  /* background-color: var(--second-color); */
  /* padding: 1.5rem; */
  border-radius: 6px;
  overflow: auto;
  height: 100%;

}

.messages-container-left__user-messages p{
  font-size:1rem;
  padding-bottom: 20px;
  padding: 1.5rem 1.5rem 1.3rem 1.5rem;

}

.messages-container-center__back,.messages-container-center-chat__show-messages{
  text-align: right;
  font-size: 1rem;
  cursor: pointer;
  margin-bottom: 1rem;
  color: white;
}

.messages-container-center-chat__show-messages{
  text-align: center;
}



.messages-container-center__back:hover,.messages-container-center-chat__show-messages:hover, .messages-container-left__user-messages p:hover, .title-top-right:hover{
  transition: all 0.3s ease;
  color: var(--main-link-button-color);
}

.messages-container-center__chat{
  background-color: var(--main-color);
  /* border-right:1px solid #AF0171; */
  /* border-radius: 6px; */
  width: 100%;
  height: 36rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.messages-container-center-chat__list-messages{
  height: 25rem;
  overflow-y: scroll;
}

.messages-container-center__each_message{
  width: 100%;
  display:flex;
  align-items: center;
  margin-bottom: 1rem;
}
.messages-container-center-chat__message{
  height: 3rem;
  padding: 1rem;
  height: 100%;
  width: 80%;
  border-radius:6px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;

}

.messages-photo{
  object-fit:contain;
  max-width:200px;
}

.messages-video{
  width: 150px;
  max-height: auto;
  border-radius:10px;

}

.video-blocked{
  filter: blur(8px);
}



.photo-messages-bl{
  cursor: pointer;
}

.photo-messages-bl span{
  margin-bottom: 15px;
}

.messages-container-right-chat__message{
  text-align: end;
  box-shadow: none;
}
.messages-container-left-chat__message{
  text-align: start;
  box-shadow: none;
}

.messages-container-center-chat__message:hover{
  opacity: .7;
}



.messages-container-center-chat__send_message{
  height: 5rem;
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 25px;
  /* border: 3px solid var(--third-color); */
  position: relative;
  /* position: absolute;
  bottom: 0; */
}

.item{
  padding-right: 10px;
}

.message-no-conversation{
  width:100%;
  text-align: center;
  line-height: 10;

}

.text-right{
  justify-content: flex-end;
}

.text-left{
  justify-content: start;
}

.user-messages__each_line{
  width: 100%;
  /* margin-bottom: 1rem; */
  justify-content: space-between;
  padding: 0.5rem 1.5rem;
}
.user-messages__each_line:hover{
  cursor: pointer;
}
.user-messages__each-image{
  width: 50px;
  height: 50px;
}

.messages__container-center{
  width:58%;
}

.messages__container-right{
  width:28%;
  background-color: var(--main-color);
}


.send-message__left{
width:10%;
display: flex;
justify-content: space-evenly;
font-size:1.2rem;
align-items: center;
}
.send-message__center{
  width:85%;
  
}

.send-message__center input{
  width:100%;
  border:none;
  outline:none;
  background-color: transparent;
  color: white;
  border-bottom: 1px solid grey;
}

.send-message__right{
  width:5%;
  font-size: 1.5rem;
}

.send-message__left svg, .send-message__right svg{
  cursor:pointer;
}
.send-message__left div{
  margin: auto;
}

.icons-centered{
  margin-bottom: 4px;
  margin-right: 8px;
}

.count-user-messages{
  padding: 6px 12px;
  background-color: var(--main-link-button-color);
  border-radius: 50%;
  font-size: 15px;
  color:white !important;
}

.message-image-upload{
    width: 100px;
    margin-top: 20px;
    border-radius: 5px;
}

@media(max-width: 1200px ){
  .messages-video{
    width:300px;
  }
}
@media (max-width: 992px)
{

  .messages{
    flex-direction: column;
  }
  .messages__container-center, .messages__container-left, .messages__container-right{
    width:100%;
    margin-top:15px;
  }
  .messages-container-left__user-messages{
    margin-top:15px;
  }

  .messages__container-left{
    height: 300px;
  }


  .send-message__right{
    width:5%;
  }
  .send-message__left{
    width:15%;
    justify-content: flex-end;
  }

  .send-message__left svg{
    margin-right:15px;
  }
  .send-message__center{
    width:80%;
  }
  .messages-container-center-chat__send_message{
    justify-content: center;
  }
   
}

@media (max-width: 580px)
{
  .send-message__left svg{
    margin-right:5px;
  }

  .send-message__right{
    width:10%;
  }
  .send-message__left{
    width:20%;
    justify-content: flex-end;
  }

  .send-message__left svg{
    margin-right:0.3rem;
  }
  .send-message__center{
    width:65%;
  }
  .messages-container-center-chat__send_message{
    justify-content: space-around;
  }
  .messages-video{
    width:200px;
  }
  .gift-name{
    display: none;
  }
}

